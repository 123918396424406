import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
// import { TransitionGroup, CSSTransition } from 'react-transition-group';
import _ from 'lodash';
import moment from 'moment';

/* loader component for Suspense */
// import PageLoader from './components/Common/PageLoader';

// import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';
import requireAuth from './components/auth/requireAuth';
import Callback from './components/auth/callback';
// import BaseHorizontal from './components/Layout/BaseHorizontal';

import Login from './components/Login/Login';
import Navbar from './components/Bank/navbar.component';
import Bank from './components/Bank/createBank.component';

/* Used to render a lazy component with react-router */
const waitFor = (Tag) => (props) => <Tag {...props} />;

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = ['login', 'callback', 'submit-quote'];

const Routes = ({ location }) => {
  setTimeout(function authCheck() {
    if (!_.isEmpty(sessionStorage.getItem('expires_at'))) {
      if (
        moment(new Date()).isSameOrAfter(
          moment(sessionStorage.getItem('expires_at'))
        )
      ) {
        sessionStorage.clear();
        window.open('/', '_parent');
      }
    }
    setTimeout(authCheck, 5000);
  }, 5000);

  //   const currentKey = location.pathname.split('/')[1] || '/';
  //   const timeout = { enter: 500, exit: 500 };

  // Animations supported
  //      'rag-fadeIn'
  //      'rag-fadeInRight'
  //      'rag-fadeInLeft'

  //   const animationName = 'rag-fadeIn';

  // alert(location.pathname.split('/')[1]);
  if (listofPages.indexOf(location.pathname.split('/')[1]) > -1) {
    return (
      // Page Layout component wrapper
      <BasePage>
        {/* <Suspense fallback={<PageLoader />}> */}
        <Switch location={location}>
          <Route path="/login" component={waitFor(Login)} />
          <Route path="/callback" component={waitFor(Callback)} />
          {/* <Route path="/navigation" component={waitFor(SubmitQuote)} /> */}
        </Switch>
        {/* </Suspense> */}
      </BasePage>
    );
  }

  return (
    // Layout component wrapper
    // Use <BaseHorizontal> to change layout
    // <Base>
    //   <TransitionGroup>
    //     <CSSTransition
    //       key={currentKey}
    //       timeout={timeout}
    //       classNames={animationName}
    //       exit={false}
    //     >
    <div>
      {/* <Suspense fallback={<PageLoader />}> */}
      <Switch location={location}>
        <Route path="/navigation" component={requireAuth(waitFor(Navbar))} />
        <Route path="/banking" component={requireAuth(waitFor(Bank))} />
        {/* <Route path="/vehicle-search" component={requireAuth(waitFor(Home))} />
                //  <Route path="/lightstone-vehicles" component={requireAuth(waitFor(LightstoneVehicles))} /> 
                <Route path="/quotes" component={requireAuth(waitFor(Quotes))} />
                <Route path="/quote-request/:id" component={requireAuth(waitFor(QuoteRequest))} />
                <Route path="/quote-request" component={requireAuth(waitFor(QuoteRequest))} />
                <Route path="/glass-maintenance" component={requireAuth(GlassMaintenance)} />
                <Route path="/update-glass/:id" component={requireAuth(UpdateGlass)} /> */}
        <Redirect exact path="/" to="/banking" />
      </Switch>
      {/* </Suspense> */}
    </div>
  );
};

Routes.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(Routes);
