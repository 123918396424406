import React from 'react';
// import logo from './logo.svg';
import './App.css';
import Routes from './Routes';
import { BrowserRouter as Router } from 'react-router-dom';
// import Navbar from './components/navbar.component';
// import CreateBank from './components/createBank.component';
// import useAuth0 from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import store from './store';

const App = () => {
  // const { isLoading } = useAuth0();

  // if (isLoading) return <div>Loading...</div>
  return (
    //fnb or standard
    <Provider store={store}>
      <Router>
        <Routes />
      </Router>
    </Provider>

    //getProcess will use Bank details
    //identify if FNB or Standard
    //one button to kick off the process F&B

    //auth0 login -> (presented with 2 buttons or two forms)

    // FNB submit
    // Standard

    //addBanking
    //type of bank
    //bank name
    //status
    //otp for standard

    //standard bank OTP => standard

    //the page of information addBanking
  );
};

export default App;

// import React from 'react';
// import { BrowserRouter } from 'react-router-dom';

// // App Routes
// import Routes from './Routes';

// // Vendor dependencies
// import './Vendor';
// // Application Styles
// import './styles/bootstrap.scss';
// import './styles/app.scss';

// const App = () => {
//   // specify base href from env varible 'PUBLIC_URL'
//   // use only if application isn't served from the root
//   // for development it is forced to root only
//   /* global PUBLIC_URL */
//   const basename = process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');

//   return (
//     <BrowserRouter basename={basename}>
//       <Routes />
//     </BrowserRouter>
//   );
// };

// export default App;
