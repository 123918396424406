/* eslint-disable consistent-return */
import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'qs';
import auth0 from 'auth0-js';
import { connect } from 'react-redux';
import moment from 'moment';

import global from '../../global';

import { SET_AUTH_USER } from './authTypes';

class Callback extends React.Component {
  UNSAFE_componentWillMount() {
    const { props } = this;
    const hash = queryString.parse(window.location.hash);

    if (Object.getOwnPropertyNames(hash).length !== 0) {
      // Set the return details to the token session
      sessionStorage.setItem('token', JSON.stringify(hash));

      // Get user details
      this.webAuth = new auth0.WebAuth(global.AUTH0.webAuth);
      this.webAuth.client.userInfo(hash['#access_token'], (error, profile) => {
        if (error) {
          return props.history.push('/login');
        }
        props.setUserAuthenticated(true, profile);
        console.log(profile);
        sessionStorage.setItem('user', profile.email);
        sessionStorage.setItem(
          'expires_at',
          moment(new Date()).add(hash.expires_in, 'seconds')
        );
        return props.history.push('/');
      });
    } else {
      sessionStorage.removeItem('token');
    }
  }

  render() {
    return <div />;
  }
}

Callback.propTypes = {
  history: PropTypes.object.isRequired,
  setUserAuthenticated: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  setUserAuthenticated: (authenticated, user) => {
    dispatch({ type: SET_AUTH_USER, value: { authenticated, user } });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Callback);
