import React, { Component } from 'react';
import global from '../../global';
import axios from 'axios';
import LogoutButton from '../LogoutButton';
import McCartyLogo from '../../Images/McCarthyLogo.png'
// import "bootstrap/dist/css/bootstrap.min.css";
// import './../supplier-List.css'

export default class CreateSupplier extends Component {
  constructor(props) {
    super(props);

    this.onChangeBank = this.onChangeBank.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeOTP = this.onChangeOTP.bind(this);
    this.onChangeVersion = this.onChangeVersion.bind(this)
    this.onChangeStatement = this.onChangeStatement.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      Bank: ['Standard Bank'],
      Username: '',
      Password: '',
      OTP: '',
      Statement: ['Provisional'],
      Version: ['Old'],
      StatementOptions: ['Provisional', 'Final'],
      VersionOptions: ['Old', 'New'],
      BankOptions: ['FNB', 'Standard Bank'],
    };
  }

  onChangeBank(e) {
    const outl = [];
    outl[0] = e.target.value;

    this.setState({
      Bank: outl,
    });
  }

  onChangeUsername(e) {
    this.setState({
      Username: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      Password: e.target.value,
    });
  }

  onChangeOTP(e) {
    this.setState({
      OTP: e.target.value,
    });
  }

  onChangeVersion(e) {
    const ver = [];
    ver[0] = e.target.value;

    this.setState({
      Version: ver,
    });
  }

    onChangeStatement(e) {
    const statem = [];
    statem[0] = e.target.value;

    this.setState({
      Statement: statem,
    });
  }

  onSubmit(e) {
    console.log('test');
    e.preventDefault();

    const bank = {
      Bank: this.state.Bank[0],
      Username: this.state.Username,
      Password: this.state.Password,
      OTP: this.state.OTP,
      Version: this.state.Version[0],
      Statement: this.state.Statement[0]
    };


    console.log(bank);
    

    const headers = { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token'))['#access_token']}` };
    axios
      .post(`${global.SUBMIT_LINK}/banking`, bank, {headers})
      .then((res) => this.setState({
        Bank: ['Standard'],
        Username: '',
        Password: '',
        OTP: '',
      })).catch((error) => console.log(error));
    
  }

  render() {
    return (
      <div>
        <img src={McCartyLogo}></img>
        <h3>Statement Download</h3>
        <form onSubmit={this.onSubmit}>
          <div className="form-group" >
            <label>Bank: </label>
            <select
              ref="userInput"
              required
              className="form-control"
              value={this.state.Bank[0]}
              onChange={this.onChangeBank}
              style={{width:"23%"}}
            >
              {this.state.BankOptions.map((bank) => (
                <option key={bank}>{bank}</option>
              ))}
            </select>
          </div>
          {this.state.Bank[0] === 'Standard Bank' ? (
            <>
              <div className="form-group">
                <label>Username: </label>
                <input
                  type="text"
                  required
                  className="form-control"
                  value={this.state.Username}
                  onChange={this.onChangeUsername}
                  style={{width:"23%"}}
                />
              </div>
              <div className="form-group">
                <label>Password: </label>
                <input
                  type="password"
                  required
                  className="form-control"
                  value={this.state.Password}
                  onChange={this.onChangePassword}
                  style={{width:"23%"}}
                />
              </div>
              <div className="form-group">
                <label>OTP: </label>
                <input
                  type="text"
                  required
                  className="form-control"
                  value={this.state.OTP}
                  onChange={this.onChangeOTP}
                  style={{width:"23%"}}
                />
              </div>
            </>
          ) : null}
           <div className="form-group" >
            <label>Statement: </label>
            <select
              ref="userInput"
              required
              className="form-control"
              value={this.state.Statement[0]}
              onChange={this.onChangeStatement}
              style={{width:"23%"}}
            >
              {this.state.StatementOptions.map((statement) => (
                <option key={statement}>{statement}</option>
              ))}
            </select>
          </div>
          <div className="form-group" >
            <label>Select Version: </label>
            <select
              ref="userInput"
              required
              className="form-control"
              value={this.state.Version[0]}
              onChange={this.onChangeVersion}
              style={{width:"23%"}}
            >
              {this.state.VersionOptions.map((version) => (
                <option key={version}>{version}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <input
              type="submit"
              value="Create Bank Log"
              className="btn btn-primary"
            />
          </div>
        </form>
      </div>
    );
  }
}
