const global = {
    SUBMIT_LINK: 'https://uftmarlcj4.execute-api.af-south-1.amazonaws.com/prod',
    AUTH0: {
        webAuth: {
          domain: 'autoboys.auth0.com',
          clientID: 'Vc2rp976Dyv7UdCd03gcTUaNEF00cCiv',
          responseType: 'token id_token',
          audience: 'dolby-api',
          scope: 'openid email',
          redirectUri: 'https://mcbstate.apollostudios.co.za/callback',
        },
        realm: 'McCarthy',
      },
    url: 'https://autoboys.auth0.com/oauth/token',
  };
  
  export default global;
  