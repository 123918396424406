/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-string-refs */
import React, { Component } from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';
import auth0 from 'auth0-js';
import NotificationSystem from 'react-notification-system';
import $ from 'jquery';
import McCartyLogo from '../../Images/McCarthyLogo.png'
import global from '../../global';
// import { style } from '../../variables/Variables';
// import './Login.css';

window.jQuery = $;
window.$ = $;
global.jQuery = $;
class Login extends Component {
  constructor(props) {
    super(props);

    this.webAuth = new auth0.WebAuth(global.AUTH0.webAuth);

    this.doSignIn = this.doSignIn.bind(this);

    this.state = {
      email: '',
      password: '',
      remember: '',
      _notificationSystem: null,
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {
    if (sessionStorage.getItem('token')) {
      this.props.history.push('/login');
    }
  }

  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.doSignIn(e);
    }
  }

  async doSignIn(e) {
    e.preventDefault();
    this.setState({ loading: true });
    this.webAuth.login(
      {
        realm: global.AUTH0.realm,
        username: this.state.email,
        password: this.state.password,
      },
      () => {
        const _notificationSystem = this.refs.notificationSystem;
        _notificationSystem.addNotification({
          title: <span data-notify="icon" className="pe-7s-shield" />,
          message: (
            <div>
              <b>Invalids User Credentials</b>: Please enter the correct Email
              and password!
            </div>
          ),
          level: 'warning',
          position: 'tc',
          autoDismiss: 5,
        });
      }
    );
    this.setState({ loading: false });
  }

  render() {
    const { state } = this;
    return (
      <div className="app align-items-center login">
        <img src={McCartyLogo}></img>
        <Container>
          <Row className="p-5">
            <Col lg="6" md="6" sm="6">
              <Card className="p-4 logincard">
                <NotificationSystem ref="notificationSystem" />
                <CardTitle style={{ borderBottom: '1px solid #c8ced3' ,  marginLeft: 5}}>
                  {' '}
                  <Row>
                    <Col style={{ paddingRight: 0}}>
                      {' '}
                      <h1 style={{ marginBottom: 0 }} className="d-flex">
                        Banking <br />
                        <span className="d-flex"> Portal</span>
                      </h1>
                    </Col>
                  </Row>
                  <p
                    className="text-muted d-flex"
                    style={{ marginBottom: 0, marginLeft: 5 }}
                  >
                    Sign in to your account
                  </p>
                  <br />
                </CardTitle>
                {this.state.loading ? (
                  <Row className="justify-content-center">
                    <div className="k-loading-image" />
                  </Row>
                ) : null}
                <CardBody>
                  <Form>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <div>Username</div>
                      <Input
                        type="email"
                        placeholder="Email"
                        autoComplete="email"
                        name="email"
                        value={state.email}
                        autoFocus
                        style={{width:"120%"}}
                        onChange={(event) => {
                          this.setState({
                            ...state,
                            email: event.target.value,
                          });
                        }}
                      />
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <div>Password</div>
                      <Input
                        type="password"
                        placeholder="Password"
                        name="password"
                        onKeyPress={(e) => this.handleKeyPress(e)}
                        autoComplete="current-password"
                        value={state.password}
                        style={{width:"120%"}}
                        onChange={(event) => {
                          this.setState({
                            ...state,
                            password: event.target.value,
                          });
                        }}
                      />
                    </InputGroup>
                    <button
                      className="btn btn-block btn-primary mt-3"
                      type="submit"
                      onKeyPress={this.doSignIn}
                      onClick={this.doSignIn}
                      block="true"
                    >
                      Login
                    </button>
                    <br />
                    <small className="float-right text-muted">
                      Don&apos;t have an account?{' '}
                      <a href="/forgot" color="link" className="px-0">
                        Click Here!
                      </a>{' '}
                    </small>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
